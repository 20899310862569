







import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentHistoryHeader from '@/components/organisms/v3/StudentHistoryHeader.vue'
import TopSubjectSituation from '@/components/organisms/v3/dashboard/TopSubjectSituation.vue'
@Component({
  components: {
    SidebarSwitcher,
    StudentHistoryHeader,
    TopSubjectSituation
  },
})
export default class SubjectSituation extends Vue {
}
