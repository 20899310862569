



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SelectWithSearch extends Vue {
  @Prop()
  private options!: { value: string | number; label: string; link?: string }[]
  @Prop({ default: '' })
  private defaultValue?: string | number
  @Prop()
  private onChange!: (value: string | number) => void

  private isActive = false
  private selectedOption = this.defaultValue || ''
  private searchText = ''
  private labelPrevious = ''

  private get filteredOptions() {
    return this.options.filter((option) => option.label.toLowerCase().includes(this.searchText.toLowerCase().trim()))
  }

  private get labelOption() {
    const label = this.options.find((item) => item.value === this.selectedOption)?.label
    if (label) {
      this.labelPrevious = label
    }
    return label || this.labelPrevious || this.selectedOption
  }

  private toggleDropdown(): void {
    this.isActive = !this.isActive
  }

  private selectOption(option: { value: string | number; label: string; link?: string }): void {
    if (option?.link) {
      window.open(option?.link, '_blank');
      return
    }
    this.selectedOption = option.value
    this.onChange(option.value)
    this.isActive = false
  }

  private filterOptions(): void {
    if (!this.isActive) {
      this.isActive = true
    }
  }

  private handleOutsideClick(event: MouseEvent) {
    if (!this.$el.contains(event.target as Node)) {
      this.isActive = false
    }
  }

  private mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  }

  private beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick)
  }
}
