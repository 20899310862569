
















import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class StudentHistoryHeader extends Vue {
  @Prop()
  activeTab?: string

  private get isShowPretest(): any {
    return this.$store.getters['activeSubjects/activeSubjects']?.length > 0
  }

  get isActive(): any {
    return function (target: string) {
      // @ts-ignore
      const { path } = this.$route
      const active = path.split('/').slice(-1)[0]
      return {
        active: target === active || this.activeTab === target,
      }
    }
  }
  private canChangeCalendarAndGradeBook = false
  private loginUser() {
    Vue.prototype.$http.httpWithToken.get('/v3/users/loginUser').then((res: any) => {
      const isOnAir = res.data.isStudentOfOnairAcademy
      if (isOnAir) {
        this.canChangeCalendarAndGradeBook = true
        return
      }
      this.canChangeCalendarAndGradeBook = res.data.showCalendarAndGradeBook
    })
  }
  private mounted() {
    this.loginUser()
  }
}
