









import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectWithSearch from '@/components/atoms/v3/SelectWithSearch.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    SelectWithSearch,
    SelectBase,
  },
})
export default class FilterMode extends Vue {
  @Prop()
  private userId!: number
  @Prop()
  private onChange!: (code: string) => void
  @Prop({ default: false })
  isTeacher!: boolean
  @Prop({ default: '' })
  defaultValue!: string

  private selectedYear = ''
  private years: { text: string; value: number }[] = []
  private constantMode = [
    { value: '', label: ' 全てのモード' },
    { value: 'AI', label: '通常学習' },
  ]
  private constantModeV1 = [
    { value: 'TJ', label: '旧通常学習', link: '/student/history/normal/learning-situation' },
    { value: 'TK', label: '定期テスト対策', link: '/student/history/test/learning-situation' },
    { value: 'KS', label: '講習会', link: '/student/history/seminar/learning-situation' },
    { value: 'NY', label: '入試対策', link: '/student/history/entrance/learning-situation' },
  ]
  private backModeV1 = [
    {
      value: 'TJ',
      label: '旧通常学習',
      link: '/teacher/history/normal-v1/learning-situation',
    },
  ]
  private modes: { label: string; value: number | string }[] = []

  private hasExistHistoryV1 = false

  private student: {
    userId: number
    nickname: string
    iconUrl: string
    studentId: number
    studentCode: string
    schoolName: string
    gradeName: string
  } = Vue.prototype.$cookies.get('dataGdls').student || {}

  private handleSelectMode(value: string) {
    this.onChange(value)
  }
  @Watch('selectedYear')
  private watchSelectYear() {
    this.getModeCustom()
  }

  private get listMode() {
    if (this.hasExistHistoryV1 && this.isTeacher) {
      return [...this.constantMode, ...this.backModeV1, ...this.modes]
    }
    if (this.hasExistHistoryV1) {
      return [...this.constantMode, ...this.constantModeV1, ...this.modes]
    }
    return [...this.constantMode, ...this.modes]
  }

  private async getModeCustom() {
    const { data: result } = await Vue.prototype.$http.httpWithToken.get('/v3/student_class_modes', {
      params: {
        page: 1,
        limit: 10000,
        year: this.selectedYear,
        all_mode: true,
        user_id: this.userId,
        with_deleted: true,
      },
    })
    const listMode = result.class_modes.map((item: any) => ({ value: item.code, label: item.name }))
    this.modes = [...listMode]
  }
  private async checkHistoryV1() {
    const params: any = {}
    if (this.isTeacher) {
      params.user_id = this.student.userId
    }
    const { data } = await Vue.prototype.$http.httpWithToken.get('/v3/students/hasHistoryV1', { params })
    this.hasExistHistoryV1 = data.exist
  }

  private created() {
    this.checkHistoryV1()
  }

  private mounted() {
    const currentYear = new Date().getFullYear()
    this.selectedYear = currentYear + ''
    this.years = new Array(currentYear - 2020)
      .fill(0)
      .map((_, index) => ({ text: currentYear - index + '', value: currentYear - index }))
  }
}
